<template>
  <div class="budget-container">
    <ul class="budget-list diff-style">
      <li class="bl-item"
        :class="{'active':mapActive == -1 }" @click="switchChangeFun(-1)">全部</li>
    </ul>
    <ul class="budget-list">
      <li
        v-for="(item,index) in list" :key="index"
        class="bl-item"
        :class="{'active':mapActive == index }" @click="switchChangeFun(index)"
      >
        <span v-if="item.max">{{item.min}}-{{item.max}}</span>
        <span v-else>{{item.min}}以上</span>
      </li>
    </ul>
    <div class="budget-field">
      <h3 class="df-title">自定义</h3>
      <div class="df-main">
        <div class="field-box">
          <el-input class="fake-inp" v-model="min_val" placeholder="最小值"></el-input>
        </div>
        <div class="cut-box"></div>
        <div class="field-box">
          <el-input class="fake-inp" v-model="max_val" placeholder="最大值"></el-input>
        </div>
        <div class="control-box">
          <el-button class="fake-btn" @click="inputConfirmFun">
            <span>确定</span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    active: {
      type: [String,Number],
      default: -1,
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapActive: this.active,
      min_val: '', // 最小值
      max_val: '', // 最大值
      list: [{
        min: '100',
        max: '500',
      },{
        min: '1000',
        max: '3000',
      },{
        min: '3000',
        max: '5000',
      },{
        min: '5000',
        max: '10000',
      },{
        min: '10000',
        max: '',
      }],
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 改变选项
     */
    switchChangeFun(index) {
      this.mapActive = index;
      let result = this.list[index];
      if(index == -1) {
        result = {
          min: '',
          max: '',
        };
      }

      // 告知弹窗变化
      this.informChangeFun({data: result});
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        type: 3,
        visible: false,
        status: 3, // 1/成功 2/取消 3/取消，关闭弹窗
        message: '取消，关闭弹窗',
        data: {},
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
    /**
     * 输入确认
     */
    inputConfirmFun() {

      // 校验数据
      let flag = this.verifyDataFun();
      if(!flag) return;

      // 如果只有最大值
      // if(!this.min_val && this.max_val || this.min_val == this.max_val) {
      //   this.min_val = this.min_val;
      //   this.max_val = this.max_val;
      // }

      // 告知弹窗变化
      this.informChangeFun({
        data: {
          min: this.min_val,
          max: this.max_val
        }
      });
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let { min_val, max_val } = this;

      // 两个都没输入
      if(!min_val && !max_val) {
        this.myMessage({
          message: '请输入最小值!'
        });
        return false;
      }

      // 是否是数字
      if(!this.REGEXP.is_number.test(min_val) || !this.REGEXP.is_number.test(max_val)) {
        this.myMessage({
          message: '请输入正确的数值!'
        });
      }

      // 是否为整数
      // if(!this.REGEXP.is_floating.test(min_val) || !this.REGEXP.is_floating.test(max_val)) {
      //   this.myMessage({
      //     message: '请输入整数!'
      //   });
      //   return false;
      // }

      // 最大值小于最小值
      if(parseInt(min_val) > parseInt(max_val)) {
        this.myMessage({
          message: '最大值不能小于最小值!'
        });
        return false;
      }

      return true;
    }
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.budget-container {
  box-sizing: border-box;
  padding: 50px 40px;
  width: 500px;
  .budget-list {
    box-sizing: border-box;
    padding-bottom: 50px;
    &.diff-style {
      padding-bottom: 0;
      .bl-item {
        min-width: auto;
      }
    }
    .bl-item {
      float: left;
      box-sizing: border-box;
      padding: 0 8px;
      margin-top: 20px;
      margin-right: 20px;
      min-width: 100px;
      height: 24px;
      line-height: 24px;
      border: 1px solid #AAAAAA;
      text-align: center;
      font-size: 14px;
      color: #787878;
      cursor: pointer;
      &.active {
        color:  $common-number1-color;
        border-color: $common-number1-color;
      }
    }
  }
  .budget-list::after,
  .budget-field .df-main::after {
      content: '';
      display: block;
      clear: both;
    }
  .budget-field {
    .df-title {
      margin-bottom: 20px;
      font-size: 16px;
      color: #444343;
    }
    .df-main {
      .field-box,
      .cut-box,
      .control-box {
        float: left;
      }
      .field-box {
        &::v-deep  {
          .el-input {
            width: 100px ;
            height: 36px;
            .el-input__inner {
              border: none;
              border-bottom: 1px solid #AAAAAA;
              border-radius: 0;
              height: 36px;
              line-height: 36px;
              padding: 0 10px;
              text-align: center;
            }
          }
        }
      }
      .cut-box::after {
        content: '-----';
        height: 36px;
        line-height: 36px;
        letter-spacing: 2px;
        font-size: 12px;
        color: #AAAAAA;
      }
      .control-box {
        width: 50px;
        height: 20px;
        line-height: 20px;
        margin: 8px 0 0 18px;
        .fake-btn {
          width: 100%;
          height: 100%;
          font-size: 14px;
          color: #fff;
          border: 1px solid $common-number1-color;
          background: $common-number1-color;
          outline: none;
          padding: 0;
          &.el-button:hover,&.el-button:focus,&.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }
        }
      }
    }
  }
}
</style>
