import BMap from 'BMap';


export default class Location {
  constructor(name) {
    // this.name = name || 'Location'; // "allmap"
    this.name = name;
    this.map = {}; // 地图对象
    // this.init(name); //初始化地图
  }
  /**
   * 初始化地图
   */
   init(name) {
    name = name || this.name;
    // 百度地图API功能
    this.map = new BMap.Map(name);  // 创建Map实例
    console.log('BMap == ',BMap)
    let map = this.map;
    return new Promise((resolve,reject) => {
      // 获取定位
      this.getLocation(this.map).then((res) => {
        console.log('获取定位 getLocation point == ',res);
        let { point } = res;
        if( name ) {
          map.centerAndZoom(point,15); // 初始化地图,用城市名设置地图中心点
          map.enableScrollWheelZoom(); // 启用滚轮放大缩小
          var marker = new BMap.Marker(point); // 创建标注
          map.addOverlay(marker); // 将标注添加到地图中
          marker.setAnimation(BMAP_ANIMATION_BOUNCE); // 跳动的动画
        }
        // 返回结果
        resolve(res);
      });
    })
  }
  /**
   * 获取定位
   */
  getLocation(map) {
    map = map || this.map;
    const _this = this;
    let options = {
      enableHighAccuracy: true, //	Boolean	是否要求浏览器获取最佳效果，同浏览器定位接口参数。默认为false
      timeout: 10000, //	Number	超时事件，单位为毫秒。默认为10秒
      // maximumAge: 0, //	Number	允许返回指定事件内的缓存结果，单位为毫秒。如果为0，则每次请求都获取最新的定位结果。默认为10分钟
      SDKLocation: true, //	Boolean	是否开启SDK辅
    }
    return new Promise((resolve,reject) => {
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(res){
        this.disableSDKLocation();
        console.log('定位 res == ',res);
        if(res) {
          let bMap_status = this.getStatus();
          console.log('您的位置：bMap_status failed '+bMap_status);
          console.log('BMAP_STATUS_SUCCESS ',BMAP_STATUS_SUCCESS);
          console.log('BMAP_STATUS_PERMISSION_DENIED ',BMAP_STATUS_PERMISSION_DENIED);
          console.log('BMAP_STATUS_TIMEOUT ',BMAP_STATUS_TIMEOUT);
          console.log('BMAP_STATUS_UNKNOWN_LOCATION ',BMAP_STATUS_UNKNOWN_LOCATION);
          switch(bMap_status) {
            case BMAP_STATUS_SUCCESS:
              console.log('定位成功！')
              console.log('您的位置：'+res.point.lng+','+res.point.lat);
              break;
            case BMAP_STATUS_PERMISSION_DENIED:
              console.log('没有权限，拒绝访问！')
              break;
            case BMAP_STATUS_TIMEOUT:
              console.log('请求超时！')
              break;
            case BMAP_STATUS_UNKNOWN_LOCATION:
              console.log('未知错误！')
              break;
          }
          resolve(res)
        } else {
          console.log('_this.$router == ',_this.$router)
          if(_this.$router && _this.$router.go) {
            _this.$router.go(0);
          }
        }
      },options);
    })
  }
  /**
   * 搜索
   */
  serach(map,keywords,callback) {
    map = map || this.map;
    var local = new BMap.LocalSearch(map, {
      renderOptions: {
        map: map, // 显示的目标地图
        // panel: "r-result", // 查询结果显示面板id 使用: <div id='r-result'></div>
      },
      pageCapacity: 100, // 页容量，取值范围：1 - 100
      onSearchComplete: function(res) { // 检索完成后的回调函数
        console.log('检索完成 res == ',res)
        console.log('检索完成 res.getCurrentNumPois() == ',res.getCurrentNumPois())
        console.log('检索完成 res.getPoi() == ',res.getPoi())
        console.log('检索完成 res.getPoi(0) == ',res.getPoi(0))
        // 判断状态是否正确
        if (local.getStatus() == BMAP_STATUS_SUCCESS){
          var arr = [];
          for (var i = 0; i < res.getCurrentNumPois(); i ++){
            arr.push(res.getPoi(i));
          }
          console.log('检索完成 arr == ',arr)
          if(callback) {
            callback(arr);
          }
        }
      }
    });
    local.search(keywords);
  }
}
