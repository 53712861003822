<template>
  <div class="location-container">
    <ul class="location-list">
      <li class="ll-item">
        <h3 class="ll-title">热门城市</h3>
        <div class="ll-main">
          <ul class="main-list">
            <li class="ml-item" v-for="(item,index) in hot_city" :key="index" @click="switchChangeFun(item,index)">{{item.name}}</li>
          </ul>
        </div>
      </li>
      <li class="ll-item" v-for="(item,index) in city" :key="index">
        <h3 class="ll-title">{{item.key}}</h3>
        <div class="ll-main">
          <ul class="main-list">
            <li class="ml-item" v-for="(citem,cindex) in item.value" :key="cindex" @click="switchChangeFun(citem,cindex)">{{citem.name}}</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    active: {
      type: [String,Number],
      default: '',
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapActive: this.active,
      city: [],
      hot_city: [],
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 获取所有城市
      this.getAllCityFun().then((res) => {
        console.log('city_form res == ',res)
        let { city, hot_city } = res;
        this.city = city;
        this.hot_city = hot_city;
      });
    },
    /**
     * 获取所有城市
     */
    getAllCityFun() {
      return new Promise((resolve,reject) => {
        // 获取缓存
        let city_form = this.myGetStorage(this.STORAGE.city_form);
        // 如果有缓存则使用缓存，没有则请求
        if(city_form) {
          // 返回结果
          resolve(city_form)
        } else {
          this.myRequest({
            url: '/api/region/cityall',
          }).then((res) => {
            console.log('获取所有城市 res == ',res)
            let resData = res.data.data;
            let { city, hot_city } = resData;
            let cityData = this.analysisCityFormFun(city); // 解析城市表
            let result = {
              city: cityData,
              hot_city: hot_city,
            };
            // 存入缓存
            this.mySetStorage(this.STORAGE.city_form,result);
            // 返回结果
            resolve(result)
          })
        }
      })
    },
    /**
     * 解析城市表
     */
    analysisCityFormFun(citys) {
      let result = [];
      // 获取城市数据
      for(let key in citys) {
        let flag = citys.hasOwnProperty(key);
        if(flag) {
          result.push({key: key, value: citys[key]});
        }
      }
      return result;
    },
    /**
     * 通过地区id获取父级数据
     */
    getCityParentFun(id,callback) {
      return new Promise((resolve,reject) => {
        this.myRequest({
          url: '/api/region/regionparent',
          data: {
            sid: id,
          }
        }).then((res) => {
          console.log('地区id获取父级数据 res == ',res)
          let resData = res.data.data;
          resolve(resData);
        });
      })
    },
    /**
     * 改变选项
     */
    switchChangeFun(item,index) {
      console.log('改变选项 item == ',item)
      console.log('改变选项 index == ',index)
      // 通过地区id获取父级数据
      this.getCityParentFun(item.id).then((res) => {
        let province = res.name;
        item.province = province;
        // 告知弹窗变化
        this.informChangeFun({data: {
          data: item,
          index: index,
        }});
      });
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        type: 1,
        visible: false,
        status: 3, // 1/成功 2/取消 3/取消，关闭弹窗
        message: '取消，关闭弹窗',
        data: {
          province: '', //	活动举行-所在省
          city: '', // 活动举行-所在市
        },
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.location-container {
  box-sizing: border-box;
  width: 400px;
}

.location-list {
  $colGutter: 40px;
  .ll-item {
    .ll-title {
      box-sizing: border-box;
      padding: 0 $colGutter;
      height: 40px;
      line-height: 40px;
      background: #F4F4F4;
      font-weight: 400;
      font-size: 14px;
      color: #AAAAAA;
    }
    .ll-main {
      box-sizing: border-box;
      padding: 0 $colGutter;
      min-height: 120px;
      .main-list {
        box-sizing: border-box;
        padding-bottom: 20px;
        &::after{
          content: '';
          display: block;
          clear: both;
        }
        .ml-item {
          float: left;
          box-sizing: border-box;
          padding: 0 4px;
          margin-top: 20px;
          margin-right: 20px;
          min-width: 60px;
          height: 24px;
          line-height: 24px;
          border: 1px solid #AAAAAA;
          text-align: center;
          font-size: 14px;
          color: #787878;
          cursor: pointer;
          &.active {
            color:  $common-number1-color;
            border-color: $common-number1-color;
          }
        }
      }
    }
  }
}
</style>
