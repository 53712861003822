<template>
  <div class="demand-container">
    <div class="demand-main">
      <ul class="sizer-module float-style">
        <li class="sm-item location-style active" @click="onSizerChangeFun(0)">
          <span class="sm-name">{{city_data.name}}</span>
          <i class="iconfont icon-dingwei1"></i>
        </li>
        <li class="sm-item" :class="{'active': sizer_current == 1}" @click="onSizerChangeFun(1)">
          <span class="sm-name">
            <span v-if="sizer_data.home_city && sizer_data.home_province">本市</span>
            <span v-else-if="sizer_data.home_province">本省</span>
            <span  v-else-if="sizer_data.home_city">本市</span>
            <span v-else>全国</span>
        </span>
          <i class="iconfont icon-arrow-right-1-icon"></i>
        </li>
        <li class="sm-item" :class="{'active': sizer_current == 2}" @click="onSizerChangeFun(2)">
          <span class="sm-name">预算</span>
          <i class="iconfont icon-arrow-right-1-icon"></i>
        </li>
        <li class="sm-item" :class="{'active': sizer_current == 3}" @click="onSizerChangeFun(3)">
          <span class="sm-name">排序</span>
          <i class="iconfont icon-arrow-right-1-icon"></i>
        </li>
      </ul>
      <div class="demand-place">
        <div class="place-main" v-if="(demand_list.length > 0)">
          <ul class="demand-list float-style" >
            <li class="dl-item" v-for="(item,index) in demand_list" :key="index">
              <div class="dl-info">
                <div class="info-content float-style">
                  <div class="info-left">
                    <div class="dl-title">
                      <h3 class="title-txt">
                        <span>标题：</span>
                        <span>{{item.theme}}</span>
                      </h3>
                    </div>
                    <div class="dl-site">
                      <span class="site-key">活动地点：</span>
                      <span class="site-val">{{item.home_province}}-{{item.home_city}}</span>
                    </div>
                    <div class="dl-price">
                      <span class="price-key">预算：</span>
                      <span class="price-val">￥{{item.budget}}</span>
                    </div>
                  </div>
                  <div class="info-right">
                    <div class="dl-countdown">
                      <span class="dl-people">{{item.number}}人投标</span>
                      <span class="countdown-box">
                        <my-count-down :end-time="item.open_end_time" :minute="false" :second="false" />
                        <span class="cd-item">后截止</span>
                        <!-- <span class="cd-item">5</span>
                        <span class="cd-item">天</span>
                        <span class="cd-item">8</span>
                        <span class="cd-item">小时</span>
                        <span class="cd-item">后截止</span> -->
                      </span>
                    </div>
                  </div>
                </div>
                <div class="info-rest">
                  <div class="dl-details float-style">
                    <em class="weight-tag" v-if="item.boutique">精选</em>
                    <div class="details-link" @click.stop="skipDemadDetailsFun(item.id)">
                      <span class="fake-a">
                        查看详情
                        <i class="iconfont icon-arrow-right-1-icon"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dl-control">
                <div class="control-btn" @click.stop="skipDemadDetailsFun(item.id)">立即投标</div>
              </div>
            </li>
          </ul>
          <div class="pagination-module">
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="sizer_data.page"
              :page-count="page_total"
              :total="data_total"
              @current-change="pageChageFun"
            >
            </el-pagination>
          </div>
        </div>
        <div class="empty-box" v-else>
          <div class="empty-icon">
            <el-image
              class="fake-img"
              :src="ossUrl + '/images/demand-img/empty.png'"
              fit="scale-down"
            ></el-image>
          </div>
          <p class="empty-text">暂无数据</p>
        </div>
      </div>
    </div>

    <el-drawer
      size="auto"
      :with-header="false"
      :visible.sync="sizer_drawer"
      direction="rtl"
      :before-close="sizerDrawerBeforeCloseFun"
    >
      <keep-alive v-show="page_temp">
        <component :is="page_temp" :active="current" :options="city_data" @on-change="drawerSizerChangeFun"></component>
      </keep-alive>
    </el-drawer>

  </div>
</template>

<script>
import Location from '@/utils/my_location.js';
import myCountDown from '@/components/my-count-down/MyCountDown.vue';
import locationTemp from '@/views/demand/components/location-temp.vue';
import placeTemp from '@/views/demand/components/place-temp.vue';
import sortTemp from '@/views/demand/components/sort-temp.vue';
import budgetTemp from '@/views/demand/components/budget-temp.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'Demand',
  // 声明一组可用于组件实例中的组件
  components: {
    myCountDown,
    'location': locationTemp,
    'place': placeTemp,
    'sort': sortTemp,
    'budget': budgetTemp,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossUrl: this.GLOBAL.ossUrl,
      location: {}, // 定位对象
      city_data: { // 城市
        id: '',
        letters: '',
        level: '',
        name: '',
        province: '',
      },
      page_temp: 'budget',
      sizer_current: null, // 筛选下标
      sizer_drawer: false, // 筛选抽屉
      sizer_data: { // 筛选参数
        type: 1, // 类型 0全部 1培训需求 2场地需求 3教练需求 后台默认全部
        page: 1, // 分页
        page_size: 6, // 条数
        sort: '', //	排序 【1综合 2最新 3预算低到高 4预算高到低】 后台默认综合排序
        min_price: '', //	最低预算
        max_price: '', //	最高预算
        home_province: '', //	活动举行-所在省
        home_city: '', // 活动举行-所在市
      },
      demand_list: [], // 数据列表
      page_total: null, // 数据总页数
      data_total: null, // 数据总条数
      current: '', // 当前选中
    }
  },
  // 计算属性：
  computed: {
  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 抽屉筛选变化
     */
    drawerSizerChangeFun(e) {
      console.log('抽屉筛选变化 e == ',e)
      this.sizer_drawer = false;// 筛选抽屉
      this.sizer_current = null;
      let { sizer_data } = this;
      this.current = '';
      let { type,data } = e;
      // type：1/定位 2/全国、省、市 3/预算 4/排序
      switch(+type) {
        case 1:
            this.city_data = data.data;
            sizer_data.home_city = data.data.name; // 活动举行-所在市
            // sizer_data.home_city = ''; // 活动举行-所在市
            // sizer_data.home_province = ''; //	活动举行-所在省
            this.current = 3;
          break;
        case 2:
            sizer_data.home_province = data.province; //	活动举行-所在省
            sizer_data.home_city = data.city; // 活动举行-所在市
          break;
        case 3:
            sizer_data.min_price = data.min; //	活动举行-所在省
            sizer_data.max_price = data.max; // 活动举行-所在市
          break;
        case 4:
            sizer_data.sort = data;
          break;
      }

      console.log('this.current ==' ,this.current)

      // 获取竞标数据
      this.getDemandDataFun();

    },
    /**
     * 筛选变化
     */
    onSizerChangeFun(index) {
      console.log('筛选变化 index == ',index)
      if(index == this.sizer_current) {
        this.sizer_current = null;
      } else {
        this.sizer_current = index;
      }

      // 筛选抽屉切换
      this.switchSizerDrawerFun();
    },
    /**
     * 筛选抽屉切换
     */
    switchSizerDrawerFun() {
      let flag = this.sizer_current;
      // 'location': locationTemp,
      // 'place': placeTemp,
      // 'sort': sortTemp,
      // 'budget': budgetTemp,
      let time_drawer = !this.sizer_drawer;
      switch(+flag) {
        case 0:
            this.page_temp = 'location';
          break;
        case 1:
            this.page_temp = 'place';
          break;
        case 2:
            this.page_temp = 'budget';
          break;
        case 3:
            this.page_temp = 'sort';
          break;
        default:
            this.page_temp = '';
            time_drawer = false;
          return;
      }
      this.sizer_drawer = time_drawer;
    },
    /**
     * 筛选抽屉-关闭前
     */
    sizerDrawerBeforeCloseFun(done) {
      this.sizer_current = null;
      done();
    },
    /**
     * 跳转竞标详情
     */
    skipDemadDetailsFun(id) {
      this.myRouterPush({path: '/demand/index?id='+id});
    },
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 初始化地图
      this.initMapLocationFun();
      // 获取竞标数据
      this.getDemandDataFun();
    },
    /**
     * 初始化地图
     */
    initMapLocationFun() {
      let location = new Location();
      console.log('竞标 初始化地图 ', )
      location.init().then((res) => {
        console.log('竞标 初始化地图 res == ', res)
        let { address } = res;
        this.location = res;
        this.city_data.name = address.city;
        this.city_data.province = address.province;
        console.log('竞标 初始化地图 this.city_data == ', this.city_data)
      });
    },
    /**
     * 获取竞标数据
     */
    getDemandDataFun() {
      let { sizer_data } = this;
      console.log('获取竞标数据 参数 sizer_data == ',sizer_data)
      this.myRequest({
        url: '/api/demand/list',
        data: sizer_data,
      }).then((res) => {
        console.log('获取竞标数据 res == ',res);
        let resData = res.data.data;
        this.demand_list = resData.data;
        this.page_total = resData.page_total;
        this.data_total = resData.data_total;
        console.log('获取竞标数据 this.demand_list == ',this.demand_list);
      }).catch((err) => {
        console.log('获取竞标数据 err == ',err);
      })
    },
    /**
     * 分页切换
     */
    pageChageFun(e) {
      console.log('分页切换 e == ',e)
      this.sizer_data['page'] = e;
      // 获取竞标数据
      this.getDemandDataFun();
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    /**
     * 获取页面数据
     */
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}

.demand-main {
  box-sizing: border-box;
  padding: 0 $theme-widht-whiteedge;
  // width: $theme-view-widht;
  max-width: $theme-view-widht;
  min-width: $theme-view-min-widht;
  margin: 50px auto;
  .float-style::after {
    content: '';
    display: block;
    clear: both;
  }
  .sizer-module {
    display: inline-block;
    width: auto;
    height: 50px;
    line-height: 50px;
    // border: 2px solid #ddd;
    .sm-item {
      $border: 1px solid #ddd;
      box-sizing: border-box;
      border-top: $border;
      border-bottom: $border;
      min-width: 142px;
      height: 100%;
      float: left;
      text-align: center;
      font-size: 18px;
      color: #444343;
      &:last-of-type {
        border-right: $border;
      }
      &.location-style {
        background: #DFEBFB;
        border-color: #DFEBFB;
      }
      &.active {
        color: $common-number1-color;
      }
      .iconfont {
        padding-left: 2px;
        position: relative;
        top: -1px;
        font-size: 12px;
        &.icon-dingwei1 {
          top: initial;
          font-size: 20px;
        }
      }
    }
  }
  .demand-place {
    min-height: 570px;
    .empty-box {
      margin-top: 150px;
      text-align: center;
      .empty-icon {
        width: 430px;
        height: 250px;
        margin: 0 auto;
        .fake-img {
          width: 100%;
          height: 100%;
        }
      }
      .empty-text {
        margin-top: 50px;
        font-size: 18px;
        color: #444343;
      }
    }
  }
  .demand-list {
    min-height: 570px;
    $rowGutter: 10px;
    $buttonWidth: 100px;
    $box-shadow: 0 0 9px -4px rgba(0,0,0,0.6);
    .dl-item {
      position: relative;
      float: left;
      box-sizing: border-box;
      padding-right: $buttonWidth;
      width: 48%;
      margin-top: 30px;
      &:nth-child(2n) {
        float: right;
      }
      .dl-info {
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        padding: 20px;
        box-shadow: $box-shadow;
        background: #fff;
        .info-content {
          .info-left {
            float: left;
            width: 300px;
          }
          .info-right {
            float: right;
            .dl-countdown {
              float: right;
              box-sizing: border-box;
              padding: 4px 8px;
              font-size: 12px;
              color: #444343;
              border: 1px solid $common-number1-color;
              .dl-people {
                margin-right: 8px;
              }
              .countdown-box {
                .cd-item {}
              }
            }
          }
        }
        .dl-title {
          .title-txt {
            line-height: 1.7;
            font-size: 16px;
            color: #444343;
          }
        }
        .dl-site {
          margin-top: $rowGutter;
          font-size: 14px;
          color: #787878;
        }
        .dl-price {
          margin-top: $rowGutter;
          .price-key {
            font-size: 14px;
            color: #787878;
          }
          .price-val {
            font-size: 18px;
            color: #E62129;
          }
        }
        .dl-details {
          width: 100%;
          margin-top: $rowGutter;
          .weight-tag {
            float: left;
            box-sizing: border-box;
            padding: 2px 4px;
            font-size: 12px;
            color: #FFFFFF;
            background: $common-number1-color;
          }
          .details-link {
            float: right;
            cursor: pointer;
            .fake-a {
              font-size: 12px;
              color: $common-number1-color;
              cursor: pointer;
              .iconfont {
                font-size: 12px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .dl-control {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        .control-btn {
          $subsidy: 10px;
          position: absolute;
          top: 5px;
          right: 0;
          bottom: 5px;
          // width: $buttonWidth;
          width: calc(#{$buttonWidth} + #{$subsidy});
          line-height: calc(#{$buttonWidth} + #{$subsidy});
          background: #F4F4F4;
          box-shadow: $box-shadow;
          writing-mode: vertical-lr;
          letter-spacing: 4px;
          text-align: center;
          font-size: 16px;
          color: #E62129;
          cursor: pointer;
        }
      }
    }
  }
  .pagination-module {
    text-align: center;
    margin: 30px 0;
  }
}
</style>
