<template>
  <span class="countdonw-container">
    <span v-if="day">
      <span class="cd-item" >{{count_down.d}}</span>
      <span class="cd-item cd-unit">天</span>
    </span>
     <span v-if="hour">
      <span class="cd-item">{{count_down.h}}</span>
      <span class="cd-item cd-unit">小时</span>
    </span>
    <span v-if="minute">
      <span class="cd-item">{{count_down.m}}</span>
      <span class="cd-item cd-unit">分</span>
    </span>
    <span v-if="second">
      <span class="cd-item">{{count_down.s}}</span>
      <span class="cd-item cd-unit">秒</span>
    </span>
  </span>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'MyCountDown',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    endTime: {
      type: String,
      default: '',
    },
    day: {
      type: Boolean,
      default: true,
    },
    hour: {
      type: Boolean,
      default: true,
    },
    minute: {
      type: Boolean,
      default: true,
    },
    second: {
      type: Boolean,
      default: true,
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      count_down: {},
      timer: null,
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    endTime: function() {
      // 初始化倒计时
      this.initCountDonwFun();
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 初始化倒计时
     */
    initCountDonwFun() {
      const _this = this;
      let endTime = this.endTime;
      _this.count_down = {};
      clearInterval(this.timer);
      this.timer = this.countDownFun(endTime,function(res) {
        _this.count_down = res;
        console.log('倒计时：res == ',res)
        if(!_this.minute && !_this.second) {
          clearInterval(_this.timer);
        }
      });

      console.log('倒计时：timer ==',this.timer)
    },
    countDownFun(data_str,callback) {
      console.log('data_str == ',data_str)
      var interval;
      // // 获取当前时间
      // var date = new Date();
      // var start = date.getTime();
      // 获取结束时间
      var endDate = new Date(data_str);
      var end = endDate.getTime();
      // 算出总需要倒计时的时间差
      // var active_time = end - start;
      clearInterval(interval);
      interval = setInterval(function() {
        // 获取当前时间
        let now = Date.now();
        let leftTime =  end - now;
        // 定义变量 d,h,m,s保存倒计时的时间
        var d,h,m,s;
        if (leftTime > 0) {
          d = Math.floor(leftTime/1000/60/60/24);
          h = Math.floor(leftTime/1000/60/60%24);
          m = Math.floor(leftTime/1000/60%60);
          s = Math.floor(leftTime/1000%60);
          if(callback) {
            callback({d,h,m,s})
          }
        } else {
          clearInterval(interval);
          if(callback) {
            callback({d:'00',h:'00',m:'00',s:'00'})
          }
        }
      },1000);
      return interval;
    }
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 初始化倒计时
    this.initCountDonwFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前
    clearInterval(this.timer);
  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.countdonw-container {
  box-sizing: border-box;
}
</style>
