<template>
  <div class="place-container">
    <h3 class="place-title">选择区域</h3>
    <ul class="place-options">
      <li class="po-item" :class="{'active':mapActive == 1 }" @click="switchChangeFun(1)">全国</li>
      <li class="po-item" :class="{'active':mapActive == 2 }" @click="switchChangeFun(2,options.province)">本省</li>
      <li class="po-item" :class="{'active':mapActive == 3 }" @click="switchChangeFun(3,options.name)">本市</li>
    </ul>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    options: {},
    active: {
      type: [String,Number],
      default: '',
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapActive: this.active
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    active: function(e) {
      this.mapActive = e;
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 改变选项
     */
    switchChangeFun(index,name) {
      this.mapActive = index;
      let province = '';
      let city = '';
      switch(+index) {
        case 2:
            province = name;
          break;
        case 3:
            city = name;
          break;
      }
      this.informChangeFun({data: {
        province: province, //	活动举行-所在省
        city: city, // 活动举行-所在市
      }});
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        type: 2,
        visible: false,
        status: 3, // 1/成功 2/取消 3/取消，关闭弹窗
        message: '取消，关闭弹窗',
        data: {
          province: '', //	活动举行-所在省
          city: '', // 活动举行-所在市
        },
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.place-container {
  box-sizing: border-box;
  padding: 50px 40px;
  width: 400px;
  .place-title {
    font-weight: 500;
    font-size: 18px;
    color: #444343;
  }
  .place-options {
    &::after{
      content: '';
      display: block;
      clear: both;
    }
    .po-item {
      float: left;
      box-sizing: border-box;
      padding: 0 4px;
      margin-top: 20px;
      margin-right: 20px;
      min-width: 60px;
      height: 24px;
      line-height: 24px;
      border: 1px solid #AAAAAA;
      text-align: center;
      font-size: 14px;
      color: #787878;
      cursor: pointer;
      &.active {
        color:  $common-number1-color;
        border-color: $common-number1-color;
      }
    }
  }
}
</style>
